import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import axios from 'axios'

function removeAllDash(strWithDash:string):string{
    const searchRegExp = /-/gi;
    const replaceWith = '';
    const result = strWithDash.replace(searchRegExp, replaceWith);
    return result;
}

export function loadPolicy(productKey){
    const { application_api_key, application_id, product_url,indexName, siteUrl,port } = useSiteMetadata()

}

export default function LoadProducts(props) {
    let {onChange, state, recordIds, recentlyAdded,ultid,featured_products, needFeaturedProd, initState="initSearch"} = props
    const { application_api_key, application_id, product_url,indexName, siteUrl,port } = useSiteMetadata()

    if (!state[initState] )
    {
        state[initState] = true
        let params: any = {}
        let load=false
        if (!!recentlyAdded)
        {
            params.recentlyAdded = recentlyAdded
            load=true
        }
        if (!!recordIds && recordIds.length > 0)
        {
            params.recordIds = recordIds
            load=true
        }

        if (!!ultid){
            params.ultid=removeAllDash(ultid)
            load=true
        }

        if (!!featured_products){
            if (featured_products.length>0){
                params.featuredProducts=featured_products       
            }
        }

        if (!!needFeaturedProd){
            params.needFeaturedProd="true"
        }

        if (load)
            axios.post(product_url, params)
            .then(function (response) {
                // TODO: Error handling
                if (response?.data?.result=="ok")
                {
                    onChange(response.data.prods, product_url, siteUrl,port, response.data.featuredProductResult)

                }
                
            })
    }

    return <></>

}
