import React, { useState } from 'react';
import axios from 'axios';

function isValidEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

const VendorEmailPopup = ({ closeFn, email: initialEmail, form_target, ultid, product }) => {
  const [email, setEmail] = useState(initialEmail);
  const [emailRequired, setEmailRequired] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('form'); // 'form', 'success', 'error'

  const premium = product && product.premium || false

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      setEmailRequired(false)
      axios.post(form_target, { email, message, ultid })
      .then(response => {
        if (response && response.data && response.data.status=="ok") {
          setStatus('success');
        } else {
          setStatus('error');
        }
      })
      .catch(() => {
        setStatus('error');
      });
    }
    else {
      setEmailRequired(true)
    }
  };

  return (
    <>
      <div id="popup"></div>
      <div id="popup_holder" className="vendor">
        <div id="popup_window">
          <div className="header vendor">
            Contact Vendor
            <i onClick={closeFn} className="fal fa-times"></i>
          </div>
          <div className="copy">
            {status === 'form' && (
              <form id="vendorForm" onSubmit={handleSubmit}>
                <p>You will be contacted at:</p>
                <input 
                  type="text" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                />
                {
                  emailRequired && <p><b>E-mail is required</b></p>
                }
                <p>Is there anything you’d like to share with the provider?</p>
                <textarea 
                  value={message} 
                  onChange={(e) => setMessage(e.target.value)} 
                  rows={4} 
                  cols={50} 
                />
                <button type="submit">Submit</button>
              </form>
            )}
            {status === 'success' && (
              <div>
                { premium && <p>The vendor will reply within 5 business days. If you have challenges reaching the vendor, please contact <a href="mailto:ltd-info@iste.org">ltd-info@iste.org</a></p> }
                { !premium && <p><b>{product.organizationName}</b> has not claimed their page yet. We'll do our best to reach out to them but you may want to go to <a target='_blank' href={product.orgUrl}>{product.orgUrl}</a> to contact them directly.</p> }
                <button onClick={closeFn}>Continue</button>
              </div>
            )}
            {status === 'error' && (
              <div>
                <p>There was an error submitting the form.</p>
                <button onClick={closeFn}>Continue</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorEmailPopup;
